<template>
  <div>
    <b-row v-if="initData != null">
      <b-col md="12">
        <b-nav tabs class="form-section">
          <b-nav-item disabled>Purchase Overview</b-nav-item>
        </b-nav>
      </b-col>
      <b-col md="6 my-1">
        <b-form-group
          label-for="target_quantity"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="Target Quantity"
        >
          <!-- :class="{
            form_error: submitError && initData.qty > qtyMax
          }" 
          :max="qtyMax"
          -->
          <b-form-input
            id="target_quantity"
            type="number"
            v-model="initData.qty"
            name="target_quantity"
            :disabled="
              initData.status != 'New' &&
                (initData.status != 'Processing' ||
                  initData.not_purchased_qty == 0)
            "
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6 my-1">
        <b-form-group
          label-for="to_be_purchased"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="To be purchased"
        >
          <b-form-input
            id="to_be_purchased"
            v-model="initData.not_purchased_qty"
            name="to_be_purchased"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <!-- <b-col md="6 my-1">
        <b-form-group
          label-for="planed"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="Planed"
        >
          <b-form-input
            id="planed"
            v-model="initData.planed"
            name="planed"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col> -->
      <b-col md="6 my-1">
        <b-form-group
          label-for="reserved"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="Reserved"
        >
          <b-form-input
            id="reserved"
            v-model="initData.reserved_qty"
            name="reserved"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6 my-1">
        <b-form-group
          label-for="confirmed"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="Confirmed"
        >
          <b-form-input
            id="confirmed"
            v-model="initData.confirmed_qty"
            name="confirmed"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6 my-1">
        <b-form-group
          label-for="delivered"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="Delivered"
        >
          <b-form-input
            id="delivered"
            v-model="initData.delivered_qty"
            name="delivered"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6 my-1">
        <b-form-group
          label-for="stored"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="Stored"
        >
          <b-form-input
            id="stored"
            v-model="initData.stored_qty"
            name="stored"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <!-- <b-col md="6 my-1">
        <b-form-group
          label-for="purchasing_quantity"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="Purchasing Quantity"
        >
          <b-form-input
            id="purchasing_quantity"
            v-model="initData.purchasing_qty"
            name="purchasing_quantity"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col>
      <b-col md="6 my-1">
        <b-form-group
          label-for="purchased_quantity"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="Purchased Quantity"
        >
          <b-form-input
            id="purchased_quantity"
            v-model="initData.purchased_qty"
            name="purchased_quantity"
            disabled
          ></b-form-input
        ></b-form-group>
      </b-col> -->
    </b-row>
  </div>

  <!-- <Others></Others> -->
</template>

<script>
// import ReferenceLink from '@/components/ReferenceLink/Index';
// import SelectLoading from '@/components/Loading/Index';
// import MerchantPartner from '@/views/content/Detail/Information/MerchantPartner';
// import Others from '@/views/content/Detail/Information/Others';
export default {
  name: 'PurchaseOverview',

  data() {
    return {
      qtyMax: 0
    };
  },
  props: {
    initData: Object,
    options: Object,
    submitError: Boolean
  },
  inheritAttrs: false,
  components: {},
  methods: {},

  computed: {},
  mounted() {
    // this.qtyMax
  },
  watch: {
    initData: function(o) {
      console.log(o.qty);
      this.qtyMax = o.qty;
    }
  }
};
</script>
<style scoped>
.image {
  width: 100%;
}
</style>
